import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout/layout";
import FabricAndSafetyLink from "../components/Partials/fabric-safety-link";
import InfoPage from "../components/Page/info-page";
import max from "lodash/max";
import LastUpdated from "../components/Partials/last-updated";

const Designs = ({ data }) => {
    const nodes = data.allContentfulInfoPage.edges;
    const extractPage = (page) => nodes.filter(x => x.node.name === page)[0].node;
    const designPage = extractPage('designs');
    const fabricsPage = extractPage('fabric-suppliers');
    return (
        <Layout page={"designs"}>
            <InfoPage skipTimestamp={true} {...designPage} />
            <ul className="design-links">
                <li><Link to="/scrubs">Scrubs</Link></li>
                <li><Link to="/gowns">Gowns</Link></li>
                <li><Link to="/masks">Masks</Link></li>
                <li><Link to="/caps">Caps</Link></li>
                <li><Link to="/bags">Bags</Link></li>
                <li><Link to="/visors">Visors</Link></li>
            </ul>
            <hr />
            <InfoPage useH2={true} skipTimestamp={true} {...fabricsPage} />
            <hr />
            <FabricAndSafetyLink />
            <LastUpdated time={max([designPage.updatedAt, fabricsPage.updatedAt])} />
        </Layout>
    );
};

export default Designs;

export const query = graphql`
    query {
        allContentfulInfoPage(filter: {name: {in: ["designs", "fabric-suppliers"]}}) {
            edges {
                node {
                    id
                    name
                    heading
                    body {
                        childMarkdownRemark {
                            html
                        }
                    }
                    showSafetyLink
                    updatedAt
                }
            }
        }
    }
`;